import React from "react"
import Layout from "../components/layout"

export default () => (
    <Layout>
        <h1 style={{paddingLeft: "2rem"}}>About Ronda</h1>
        <div style={{maxWidth: "600px", margin: "0 auto", display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center"}}>
            
            <img src={require("../images/ronda.jpg")} alt="Ronda" style={{width: "300px", display: "inline-block"}}/>
            <p style={{width: "50%", minWidth: "300px", display: "inline-block", padding: "1rem", lineHeight: "1.5rem", textAlign: "justify"}}>Ronda Lunn is a graphic artist and web developer based in Charlotte, NC. 
                She graduated from the University of Miami with a Bachelor of Science 
                in Computer Science and minors in Art and Mathematics.
                She also has a Master of Public Health and Bachelor's in Biology from 
                Nova Southeastern University.</p>
        </div>
    </Layout>
)